<template>
  <div class="container">
    <!-- 返回头部 -->
    <el-backtop></el-backtop>

    <!-- 头部区域 -->
    <Header :headerData={} />

    <div class="paper">
      <el-image v-for="(p, i) in cover" :key="i" 
        :src=p 
        alt=""
        :preview-src-list="all_img"></el-image>
      <!-- <el-image :src=cover[1] alt=""></el-image> -->
    </div>

    <div class="panel">
      <div class="box"
        v-for="(p, i) in paper" :key="i"
        >
        <div class="img" >
          <img :src=article_img[i] alt="">
        </div>
        <h2>{{p.title}}</h2>        
        <div class="author-dl">
          <p class="author">{{p.author}}, 2024/03</p>
          <el-button type="primary" icon="el-icon-download" size="mini" el-icon--right style="padding:0 7px;" @click="clickDownLoadPdf(i)">PDF</el-button>
        </div>
        <p class="abstract">{{p.abstract}}</p>
        <a :href="'https://doi.org/' + p.DOI">{{p.DOI}}</a>
      </div>
    </div>

    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import Footer from "../../components/Footer.vue";

export default {
  name: "Home",
  data() {
    return {
      paper : [
        {
          DOI: "10.5281/zenodo.10934495",
          title: "Two newly isolated phages infecting Vibrio parahaemolyticus", 
          author: "Kate Lin",
          abstract:"Vibrio is a bacterial genus that can be widely found in estuarine, coastal, and open-ocean waters around the globe. Vibrio alginolyticus and Vibrio parahaemolyticus specifically are known for being pathogenic, causing infections (known as ‘vibriosis’) in marine organisms and humans. Development of effective methods for treating Vibrio is essential. Considering the rapid development of antibiotic resistance, phage therapy emerges as a potentially more sustainable and effective solution, prompting continued efforts to isolate new phages that target pathogenic Vibrio species."
        }, 
        {
          DOI: "10.5281/zenodo.10935145",
          title: "Bacteria found in biofilm community and on aplysia",
          author: "Mia, Yuhan Chen",
          abstract:"Marine bacteria are largely unexplored and represent a tremendous source for natural products with potential applications. Biofilm is a microbial community with a variety of microbes but have not been well studied. Sea hares are mollusks famous for their rich chemical weapons. In this study, I isolated bacteria from biofilm samples collected from Dasha River and the sea hare Phidiana militaris. After multiple rounds of purification and DNA sequencing, bacterial species including Pseudoalteromonas piscicida, Vibrio neptunius, Vibrio harveyi, Pseudoalteromonas galatheae, Pseudoalteromonas sp., Lysobacter xinjiangensis, Micrococcus luteus, Streptomyces fumigatiscleroticus,"
        },
        {
          DOI: "10.5281/zenodo.10935225",
          title: "Biofoulings on biofoulings: Analysis of relationship between fouling behaviors of organisms in Shenzhen Bay", 
          author: "HouYu Tong",
          abstract: "In the marine ecosystem, many foulers and benthic organisms settle not only on the inorganic surface but also on other living foulers. Certain species of foulers may favor different surface components to attach to. In this experiment, biofouling samples that are attached to other foulers are collected in Dapeng (eastern part of Shenzhen), and photo and rough classification are recorded. This is followed by a PCR test on the genetic sequence of the mitochondrial gene cytochrome oxidase I (COI). The results species identification are then compared and contrasted with the rough classification to identify the final classification. The result demonstrates a pattern of fouling on certain species of foulers. The result also reveals a phenomenon that contributed to the differences between PCR classification and rough classification."
        },
        {
          DOI: "10.5281/zenodo.10935260",
          title: "Research on seafood: Distribution and origin", 
          author: "Susana Zhou",
          abstract:"Seafood, as the word implies, is basically sea animals that are being served as food. Being actively eaten as a significant part of the human diet, people either search for them in the ocean or they cultivate them in mass numbers. The question is raised in where the origins of these sea products are? Which farm they came from, or which regions in the oceans are they found in. I took samples of shellfish and crustaceans to amplify the mitochondrial COI DNA fragments. These DNA fragments are then used for species identification. As many as 7 out of the 13 specimens were successfully identified, and most of the species belong to the class Bivalvia. Others respectively belonged to the Gastropoda and Crustacea. Some of the specimens were identified to be similar species with differences spotted in morphological features. Analysis of geographical distribution of these identified species is now underway and is expected to yield further information on the possible geographic origin of these seafood species."
        },
        {
          DOI: "10.5281/zenodo.10935321",
          title: "Ecology of biofouling community", 
          author: "Hailey, XIN YI WANG",
          abstract:"Dapeng, located in the eastern part of ShenZhen, is an area recognized for its seafood cuisine. This location is home to several natural reserves, preserving unique ecosystems. The clear water there made it a preferable place to collect samples. In addition, as lying in a fault uplift zone, the rocky coasts occupy most of the coastal line of Dapeng Peninsula. Typical coastal erosion landforms such as sea cliffs, wave cut benches, sea caves, sea arches and sea stacks can thus often be found on the coasts [1]. Because of these factors, we went to Dapeng in ShenZhen. There, I collect biofouling communities at a fishfarm. I collected specimen from the biofouling community and performed PCR of COI gene for species identification. Nine specimens were collected, and the species identities of DP6H & DP7H, DP8H & DP9H and DP14H & DP15H specimens were determined by COI sequence respectively, including the gastropod Polynoidae sp. SS-2021, Parhyale hawaiensis and Thelepus japonicus."
        },
        {
          DOI: "10.5281/zenodo.10935362",
          title: "Sampling in Shenzhen Bay: Biodiversity and environment", 
          author: "Diarmuid Chu",
          abstract:"Shenzhen Bay was originally mud flat, but the coastline of nowadays days’ Shenzhen Bay was artificial. In this summer, a sampling was done for studying the biodiversity of Shenzhen Bay. The sampling started in Shenzhen Bay at 5 P.M. which was the time that intertidal zone started to exist. The intertidal zone of Shenzhen was mostly composed of mud flats. Only the part beside the beach was filled with rocks. This feature is due to land reclamation, so that the original habitats of marine organisms were destroyed. The components of Shenzhen Bay’s bio-community were mostly crabs, mudskipper, sea snail and oyster. After collecting those organisms, they were stored in a plastic box. For oyster, the shell was broken and the soft body was put into the centrifugal tube. By doing PCR, the biodiversity of this bay can be discovered."
        },
        {
          DOI: "10.5281/zenodo.10935380",
          title: "Documentation of actinopterygii (ray-finned fish), and decapods (crabs and shrimps) found in Dapeng, Shenzhen", 
          author: "Daniel K. H. Yu",
          abstract:"Dapeng is a district located in the easternmost extremity of Shenzhen and to the northeast of Hong Kong. We went to a fish farm raft located in Dapeng to collect specimens and observe the district’s biodiversity. In order to assess the impact of human activity on the local environment, I decided to launch a complete investigation and guide to the marine organisms inhabiting the area, observing what species have adapted to this polluted environment."
        },
        {
          DOI: "10.5281/zenodo.10935434",
          title: "An efficient 3D color optical microscopic imaging system through high precision biological focal stacking",
          author: "Ziqian Hong",
          abstract:"Optical microscopic imaging is an effective tool for biological research. However, limitations in traditional microscopy present difficulties in colored 3D imaging. Also, the shallow depth of field of the microscope cannot generate an all-infocus image, posing a challenge to biological observation. This paper proposes a new precise colored 3D microscopic imaging system to increase the accuracy of biological imaging and taxonomy. The proposed method consists of a high-precision automated focal scanning system (AFS) and an efficient focal stacking algorithm. The AFS is capable of precisely moving a minimum distance of 10 μm along the z-axis. Our focal stacking algorithm identifies the in-focus regions through the Laplacian pyramid to generate colored and depth information. Experimentation shows that the proposed method yields high-resolution colored images and precise depth information. The study provides the foundation for future 3D reconstruction and accurate species identification."
        },
        {
          DOI: "10.5281/zenodo.10935458",
          title: "Image segmentation in the brain coral Platygyra sp. using YOLOv8 algorithm",
          author: "Bella Chen",
          abstract:"Coral reefs play a crucial role in the intricate marine ecosystem for its impact in multiple fields. However, corals are currently facing unprecedented threats, particularly in Hong Kong, emphasizing the urgency of coral conservation and studies. The brain coral Platygyra sp., one of the most common in Hong Kong can provide valuable insights into the overall health of coral ecosystems. Understanding the need for precise data on the brain coral Platygyra sp., a specialized model has been developed for underwater brain coral Platygyra sp. image segmentation. This model is trained on a customized dataset and the YOLOv8 network. However, it faces challenges such as indistinctive boundaries and underwater photography limitations that hinder improvements in precision. Despite facing challenges, the model achieved the highest precision rate of 0.936, recall rate of 0.755, and mAP@50 rate of 0.841. The precise identification of the brain coral Platygyra sp. aids scientists in restoration and conservation efforts, providing critical information on their health condition and the  istribution of the overall ecosystem."
        },
        {
          DOI: "10.5281/zenodo.10935491",
          title: "Predicting dandelion’s dispersal and evaluating its invasive impact",
          author: "Yanguo Zhao, et al.",
          abstract:"Appearing worldwide, the dandelion, scientifically known as Taraxacum officinale, is a plant originating from Eurasia. It features a yellow flower and a ’puffball’ seed head, with seeds attached to pappus, that disperse through wind aid. Their resilience to harsh environments allows them to thrive in diverse conditions, making them invasive to ecosystems. To comprehend the dandelion’s dispersal and behavior, our task involves developing a model predicting its spread over 1, 2, 3, 6, and 12 months. Additionally, we aim to assess the dandelion’s incisiveness by computing the impact factor on its ecosystem and comparing it with two other plant species. For Task 1, an inverse Gaussian distribution model predicts dandelion distribution in a one-hectare plot over 12 months in New York State. We apply the inverse Gaussian distribution algorithm, calculating the probability of dandelion dispersal to specific locations. Considering multiple environmental factors impacting survival rates, we simulate two-dimensional random walks for dandelions using Python. We also investigate population sensitivity to input parameter changes through Python-based sensitivity analysis, quantifying each parameter’s impact on the population and graphing the results."
        },
      ],

      cover: [
        require('../../assets/img/ysa/v3i1/v3i1_cover.jpg'),
        require('../../assets/img/ysa/v3i1/v3i1_back.jpg'),
      ],

      article_img:[
        require('../../assets/img/ysa/v3i1/1_3(1)_p1-8_Kate.jpg'),
        require('../../assets/img/ysa/v3i1/2_3(1)_p9-14_Mia.jpg'),
        require('../../assets/img/ysa/v3i1/3_3(1)_p15-18_Harry.jpg'),
        require('../../assets/img/ysa/v3i1/4_3(1)_p19-24_Susana.jpg'),
        require('../../assets/img/ysa/v3i1/5_3(1)_p25-28_Hailey.jpg'),
        require('../../assets/img/ysa/v3i1/6_3(1)_p29-32_Zhihan.jpg'),
        require('../../assets/img/ysa/v3i1/7_3(1)_p33-40_Daniel.jpg'),
        require('../../assets/img/ysa/v3i1/8_3(1)_p41-48_James.jpg'),
        require('../../assets/img/ysa/v3i1/9_3(1)_p49-56_Bella.jpg'),
        require('../../assets/img/ysa/v3i1/10_3(1)_p57-73_HiMCM.jpg'),
      ],
      
      all_img : [],

      preface: [
        'Charles Darwin is considered one of the most influential scientists in the field of biology. He begun working on the topic of evolution in 1837, drawn up "some short notes" after five years, enlarged these into a sketch in 1844, and finally published the masterpiece “On the Origin of Species” on 24 November 1859. Darwin’s experience has three messages. Firstly, curiosity toward nature, as the development of his evolutionary theories started with his long observations on a wide range of organism species. Secondly, successive concentration and passion on a topic, as it took him more than 20 years to perfect his evolutionary theories. Finally, good scientific works should be supported by a body of scientific evidence.',
        'It is therefore our wish that young students would express curiosity toward nature, eager to observe, dare to ask why, and more importantly stay on the topic and find out the answer. We see The Young Scientist as a platform for students to be young scientists. As a group of active scientific researchers, we hope that by publishing decent scientific works conducted by students, we can encourage more of you to join us, doing interesting experiments, using new technologies and help us to extract novel but critical findings that would have been failed to be discovered using traditional methods.',
        'We believe the greatest scientists of the next century are among you!',
      ],
      preface_note: "Editorial Board of The Young Scientist",
    };
  },    
  components: {
    Footer,
    Header,
    // SectTurnBook,
  },

  mounted() {
    this.all_img = this.cover.concat(this.article_img);
  },

  methods: {
    clickDownLoadPdf(i){
      // console.log(i)
      window.location.href ='../public/ysa_v3i1a' + (i+1) + '.pdf' ;
    }
  }
}

</script>

<style lang="less" scoped>
.panel {
  // margin-bottom: 40px;
  width: 1200px;
  margin: 20px auto;
  columns: 4;
  column-gap: 40px;
  // overflow: hidden;
}
.panel .box {
  width: 100%;
  margin: 0 0 20px;
  padding: 10px;
  background: #fff;
  // overflow: hidden;
  // break-inside: avoid;
}
.panel .box img {
  max-width: 100%;
}
.panel .box h2 {
  margin: 10px 0 0;
  padding: 0;
  font-size: 18px;
}
.panel .box p {
  margin: 10px 0 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.2em;
  transition: 0.5s;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
}
.author-dl {
  display: flex;
  justify-content:space-between
}
.author {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 900;
    color: #005eff;
  }
.abstract::before {
  content:  "Abstract. ";
  font-weight: 900;
}
.paper {
  text-align: center;
  .el-image {
    max-width: 600px;
    width:40%;
    margin: 10px 5px;
  }
}
@media (max-width: 1200px) {
  .panel {
    columns: 3;
    width: calc(100% - 40PX);
    box-sizing: border-box;
    padding: 20px 20px 20px 0;
  }
}
@media (max-width: 768px) {
  .panel {
    columns: 2;
  }
}
@media (max-width: 480px) {
  .panel {
    columns: 1;
  }
}
</style>
